import React from 'react';

export default function Reviews(){


    return (
        <>
            <div style={{
                height:"500px",
                width : "auto",
                backgroundColor : "yellowgreen",
                textAlign : "center"
                }}>
                <h1> Reviews page </h1>
            </div>
        </>
    )
};