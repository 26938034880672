import React from 'react';

export default function ProductsIncome(){


    return (
        <>
            <div style={{
                height:"500px",
                width : "auto",
                backgroundColor : "grey",
                textAlign : "center"
                }}>
                <h1> ProductsIncome page </h1>
            </div>
        </>
    )
};