import React from 'react';

export default function HostVans(){


    return (
        <>
            <div style={{
                height:"500px",
                width : "auto",
                backgroundColor : "grey",
                textAlign : "center"
                }}>
                <h1> HostVans page </h1>
            </div>
        </>
    )
};