const sampleUserData = [
    {
        "userId": "d433c137-c443-412e-87f4-072ec9c2a2c7",
        "username": "Adella_Konopelski53",
        "firstName": "Emmet",
        "lastName": "Watsica",
        "email": "Rahsaan0@yahoo.com",
        "aboutMe": "empire lover, inventor",
        "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/179.jpg",
        "password": "jQh91g_YCvRi_uY",
        "birthdate": "21/10/1985",
        "registeredAt": "01/01/2023",
        "userInfo": {
            "biograph": "fairness supporter, friend",
            "company": "Possimus culpa porro rerum quam ipsa repellendus. Assumenda quaerat aut maiores aperiam. Sed molestiae vitae officiis occaecati quibusdam consequatur nulla commodi.",
            "jobTitle": "Lead Web Developer",
            "school": "Officiis libero repudiandae beatae cumque numquam reprehenderit rem. Animi cupiditate cum dolores. Vero voluptatibus corrupti.",
            "languages": "Cum adipisci nisi alias sint. Quia blanditiis laboriosam expedita. Vel esse animi amet at ratione.",
            "socialMedia": {
                "facebook": "https://private-vista.info/",
                "twitter": "https://shocking-doubling.com",
                "instagram": "https://chief-spirituality.com/",
                "linkedin": "https://colossal-lacquerware.net/",
                "youtube": "https://somber-workplace.info/",
                "website": "https://automatic-experiment.info/"
            }
        },
        "followersCount": 58,
        "followingCount": 5,
        "postsCount": 6,
        "likesCount": 6,
        "commentsCount": 8,
        "savedCount": 6
    },
    {
        "userId": "fdc6fd03-816a-4a2d-b93b-45fae5340241",
        "username": "Braeden25",
        "firstName": "Antonette",
        "lastName": "Wilderman",
        "email": "Clement_Trantow62@hotmail.com",
        "aboutMe": "developer",
        "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/463.jpg",
        "password": "_7px6tHPuobca66",
        "birthdate": "08/01/1983",
        "registeredAt": "11/10/2022",
        "userInfo": {
            "biograph": "mall lover, coach",
            "company": "Occaecati iure a optio blanditiis facilis. Dolorum ullam molestiae aliquam repellendus. Magnam expedita fugiat.",
            "jobTitle": "Principal Paradigm Assistant",
            "school": "Quis id quas assumenda eos id non. Nihil aliquid nemo quia iusto velit sed deserunt. Adipisci molestiae eaque ipsum maxime velit nesciunt molestiae quod vero.",
            "languages": "Perferendis facilis blanditiis repellendus accusantium minima. Sit quaerat voluptas earum accusamus quas cum. At quod eaque voluptatum accusantium.",
            "socialMedia": {
                "facebook": "https://functional-wrecker.com",
                "twitter": "https://dependent-collar.com/",
                "instagram": "https://affectionate-escalator.info",
                "linkedin": "https://distinct-miss.net/",
                "youtube": "https://cooperative-bustle.info/",
                "website": "https://that-gambling.net/"
            }
        },
        "followersCount": 22,
        "followingCount": 5,
        "postsCount": 7,
        "likesCount": 8,
        "commentsCount": 6,
        "savedCount": 7
    },
    {
        "userId": "1372ac8f-4f94-4178-b434-7b46d4abf7df",
        "username": "Brice.Altenwerth57",
        "firstName": "Hubert",
        "lastName": "Feeney",
        "email": "Melyna_Rutherford@gmail.com",
        "aboutMe": "vol junkie, veteran ❗",
        "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/394.jpg",
        "password": "2SmHpazjkSXv2fR",
        "birthdate": "06/08/1973",
        "registeredAt": "01/02/2023",
        "userInfo": {
            "biograph": "public speaker",
            "company": "Dolorum harum libero incidunt libero. Sapiente officia explicabo eos quisquam repellat tenetur repudiandae enim cum. Error a at praesentium inventore ea quidem aspernatur animi repellat.",
            "jobTitle": "National Metrics Technician",
            "school": "Pariatur perferendis atque fugiat odio ex quae iure quod odio. Cupiditate placeat fugiat itaque. Iste voluptatibus illum dolore ad.",
            "languages": "Eveniet velit officia numquam illo asperiores. Accusamus natus qui voluptatem consectetur facere dolorem non ad. Tempore quidem facilis sapiente aliquid qui temporibus.",
            "socialMedia": {
                "facebook": "https://black-churn.biz/",
                "twitter": "https://everlasting-reflection.info",
                "instagram": "https://smug-oncology.biz/",
                "linkedin": "https://oily-running.name",
                "youtube": "https://tangible-okra.com/",
                "website": "https://sure-footed-intellect.info"
            }
        },
        "followersCount": 53,
        "followingCount": 4,
        "postsCount": 7,
        "likesCount": 5,
        "commentsCount": 5,
        "savedCount": 7
    },
    {
        "userId": "9e5d95ae-f94a-4f21-aebf-d8c4ca4b4308",
        "username": "Cristal.Frami81",
        "firstName": "Bridie",
        "lastName": "Lang",
        "email": "Kieran_Stoltenberg@gmail.com",
        "aboutMe": "parent, inventor",
        "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1206.jpg",
        "password": "wFZzG4rjYzXHuUo",
        "birthdate": "06/06/1995",
        "registeredAt": "12/12/2022",
        "userInfo": {
            "biograph": "chopsticks enthusiast, patriot",
            "company": "Quo consequatur amet a voluptatem placeat magnam officia labore incidunt. Id dignissimos dolore nostrum nostrum a. Deleniti vitae nemo inventore quia quibusdam fugit sapiente quisquam a.",
            "jobTitle": "Investor Brand Facilitator",
            "school": "Architecto reiciendis a expedita totam numquam atque. Placeat dolorem libero porro iure aut sit magni in totam. Amet optio expedita delectus odit impedit rerum.",
            "languages": "Quisquam voluptatibus reiciendis velit asperiores eius vel et eveniet. Quos numquam neque blanditiis similique ea. Tempora rerum accusamus officia sed corrupti animi vel corporis.",
            "socialMedia": {
                "facebook": "https://thick-oats.info",
                "twitter": "https://greedy-speed.org/",
                "instagram": "https://upset-schema.info",
                "linkedin": "https://nonstop-bafflement.com",
                "youtube": "https://excited-dishwasher.com/",
                "website": "https://foolish-horizon.net"
            }
        },
        "followersCount": 44,
        "followingCount": 8,
        "postsCount": 4,
        "likesCount": 8,
        "commentsCount": 8,
        "savedCount": 8
    },
    {
        "userId": "35716d0c-f87d-436f-87a1-9b1033188bfd",
        "username": "Rosanna_Schaden",
        "firstName": "Gladys",
        "lastName": "Klein",
        "email": "Candelario80@hotmail.com",
        "aboutMe": "environmentalist, coach",
        "avatar": "https://avatars.githubusercontent.com/u/76650131",
        "password": "HTWu3I6KJ7omY3z",
        "birthdate": "13/02/1986",
        "registeredAt": "06/05/2023",
        "userInfo": {
            "biograph": "adrenalin supporter, author 🍿",
            "company": "Eius esse nesciunt dolorum sapiente voluptatibus temporibus pariatur fugiat. Ipsa illum amet ut rem. Impedit aspernatur aspernatur.",
            "jobTitle": "Internal Applications Assistant",
            "school": "Occaecati eveniet quaerat totam. Ex omnis ducimus necessitatibus quas ipsam laudantium earum delectus. Dolore quae libero iure dolore.",
            "languages": "Eos quia impedit sapiente non possimus. Vel unde doloremque nesciunt accusantium ipsum labore optio dolor earum. Asperiores similique unde similique.",
            "socialMedia": {
                "facebook": "https://somber-barometer.org",
                "twitter": "https://conscious-pathway.com",
                "instagram": "https://monumental-eyebrow.info",
                "linkedin": "https://frayed-catalysis.biz",
                "youtube": "https://impeccable-mustard.com",
                "website": "https://merry-daddy.com"
            }
        },
        "followersCount": 53,
        "followingCount": 8,
        "postsCount": 6,
        "likesCount": 8,
        "commentsCount": 8,
        "savedCount": 7
    },
    {
        "userId": "c6797856-a7f8-48d8-8f2e-1d02b36f59c4",
        "username": "Clinton_Schmeler7",
        "firstName": "Price",
        "lastName": "Bernhard",
        "email": "Heather.Roob@hotmail.com",
        "aboutMe": "philosopher, author, leader",
        "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1077.jpg",
        "password": "_JIloMgvse2nKsu",
        "birthdate": "18/04/1994",
        "registeredAt": "29/04/2023",
        "userInfo": {
            "biograph": "musician, dreamer, geek 🍏",
            "company": "Nemo debitis quo vitae maiores iste dicta. Ipsam in laboriosam deleniti magnam. Nihil labore esse.",
            "jobTitle": "Human Tactics Planner",
            "school": "Expedita quam ab officia aliquid repellat. Accusamus quis rem nisi ut consequatur. Excepturi iure eius.",
            "languages": "Numquam nobis molestiae. Recusandae hic earum eum mollitia. Sequi esse dolorum hic soluta facere sed qui veritatis ex.",
            "socialMedia": {
                "facebook": "https://capital-crumb.info/",
                "twitter": "https://clean-aluminum.name/",
                "instagram": "https://filthy-mop.com",
                "linkedin": "https://discrete-hospitality.net",
                "youtube": "https://left-turf.info",
                "website": "https://rosy-clinic.name/"
            }
        },
        "followersCount": 65,
        "followingCount": 4,
        "postsCount": 8,
        "likesCount": 4,
        "commentsCount": 8,
        "savedCount": 4
    },
    {
        "userId": "5f9de3b9-9f51-49ae-bcf4-77adb86a9932",
        "username": "Maureen.Stroman-Waters73",
        "firstName": "Johnathon",
        "lastName": "Hessel",
        "email": "Adan17@gmail.com",
        "aboutMe": "feeding supporter",
        "avatar": "https://avatars.githubusercontent.com/u/89728746",
        "password": "GGlfwpZINRRj6JY",
        "birthdate": "20/11/1995",
        "registeredAt": "18/03/2023",
        "userInfo": {
            "biograph": "cascade advocate",
            "company": "Nisi temporibus neque velit repellendus minima saepe aspernatur dolorum asperiores. Omnis natus veniam. Suscipit assumenda deleniti quas dolores nisi laborum adipisci.",
            "jobTitle": "Dynamic Operations Coordinator",
            "school": "Corporis veritatis dolorem asperiores hic ad deserunt harum error. Dolor deleniti pariatur. Facilis quam quae molestias molestias dolores voluptate veritatis.",
            "languages": "Sapiente voluptate sunt accusantium totam. Pariatur quo excepturi esse unde laudantium. Dolor eius deserunt.",
            "socialMedia": {
                "facebook": "https://immaterial-beanstalk.info/",
                "twitter": "https://svelte-platinum.org",
                "instagram": "https://oblong-drag.biz",
                "linkedin": "https://parallel-cashew.org/",
                "youtube": "https://leading-response.name",
                "website": "https://curly-priesthood.biz/"
            }
        },
        "followersCount": 65,
        "followingCount": 5,
        "postsCount": 7,
        "likesCount": 7,
        "commentsCount": 5,
        "savedCount": 8
    },
    {
        "userId": "496a2193-72ad-402e-9a18-613668593298",
        "username": "Gordon.Lueilwitz65",
        "firstName": "Griffin",
        "lastName": "Sipes-Bernhard",
        "email": "Christina27@gmail.com",
        "aboutMe": "grad, designer, developer",
        "avatar": "https://avatars.githubusercontent.com/u/68437539",
        "password": "rNO3f3fHi0AYtOu",
        "birthdate": "18/12/1998",
        "registeredAt": "25/10/2022",
        "userInfo": {
            "biograph": "midline enthusiast, foodie",
            "company": "Voluptate amet commodi odit a eum dolore fugiat magni asperiores. Sit quis ipsam consectetur fuga. Minima consectetur error incidunt ipsam quod.",
            "jobTitle": "District Mobility Representative",
            "school": "Dolorum voluptatem laudantium odit repellat error praesentium. Fugit ratione impedit exercitationem eos reiciendis sequi. Dignissimos quaerat et a voluptates tempore occaecati sapiente.",
            "languages": "Voluptatibus accusamus soluta voluptatibus magni. Architecto modi quos tempora sequi sunt odit exercitationem officiis. Totam quasi similique.",
            "socialMedia": {
                "facebook": "https://incomparable-pumpkin.org",
                "twitter": "https://ornate-step-son.org/",
                "instagram": "https://trustworthy-liver.info/",
                "linkedin": "https://pricey-lettuce.net",
                "youtube": "https://stingy-desktop.net/",
                "website": "https://another-co-producer.biz/"
            }
        },
        "followersCount": 77,
        "followingCount": 6,
        "postsCount": 7,
        "likesCount": 4,
        "commentsCount": 5,
        "savedCount": 7
    },
    {
        "userId": "da9a866f-596a-4eab-9cff-137a50aa333a",
        "username": "Jazlyn72",
        "firstName": "Michael",
        "lastName": "Cummerata",
        "email": "Kade17@hotmail.com",
        "aboutMe": "anteater lover, educator 🇧🇬",
        "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1160.jpg",
        "password": "M2jPhkDv2D0Zrnh",
        "birthdate": "26/07/1991",
        "registeredAt": "20/09/2022",
        "userInfo": {
            "biograph": "snakebite fan  💸",
            "company": "Commodi earum voluptatibus. Aliquid quia occaecati eaque perferendis praesentium voluptas recusandae. Nam a magnam.",
            "jobTitle": "Lead Communications Coordinator",
            "school": "Perferendis voluptas porro libero quisquam reiciendis nostrum deleniti numquam id. Vero dicta fugit numquam culpa eveniet. Tempore blanditiis soluta quisquam at expedita.",
            "languages": "Perferendis exercitationem deleniti sint esse voluptates pariatur ipsa natus. Sunt quibusdam nulla et magnam dolorum necessitatibus rem. Rem minima sed maiores quia necessitatibus omnis beatae provident ullam.",
            "socialMedia": {
                "facebook": "https://questionable-cygnet.name",
                "twitter": "https://cooked-pattypan.net/",
                "instagram": "https://naive-toothpick.info",
                "linkedin": "https://great-route.biz/",
                "youtube": "https://fabulous-cop.info/",
                "website": "https://affectionate-vise.org/"
            }
        },
        "followersCount": 47,
        "followingCount": 8,
        "postsCount": 5,
        "likesCount": 6,
        "commentsCount": 5,
        "savedCount": 5
    },
    {
        "userId": "5abe82c6-a00e-487a-89fe-ea774bbf257d",
        "username": "Jakob25",
        "firstName": "Mallory",
        "lastName": "Zieme",
        "email": "Hildegard_Fadel23@hotmail.com",
        "aboutMe": "veteran, leader",
        "avatar": "https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/1100.jpg",
        "password": "bJEVtlQEUJ2JsWC",
        "birthdate": "02/06/1993",
        "registeredAt": "13/10/2022",
        "userInfo": {
            "biograph": "physiology supporter, streamer",
            "company": "Non dolores quod praesentium quisquam commodi cum. Ullam eos quam. Animi molestias sed quibusdam dolores ad ipsum esse eum voluptatibus.",
            "jobTitle": "National Factors Representative",
            "school": "Quia delectus fugit id esse aperiam minus nisi. Laboriosam quaerat ipsa et eius praesentium dolor odio in quas. Doloremque aperiam est illo.",
            "languages": "Dolorem quos totam cumque. Voluptatum pariatur recusandae commodi laudantium. Nesciunt nemo dicta doloribus odit voluptates adipisci.",
            "socialMedia": {
                "facebook": "https://mindless-embellishment.net/",
                "twitter": "https://failing-eyebrow.com/",
                "instagram": "https://handmade-ginseng.biz/",
                "linkedin": "https://easy-going-jumper.biz/",
                "youtube": "https://silly-gender.org/",
                "website": "https://thoughtful-hurt.com"
            }
        },
        "followersCount": 22,
        "followingCount": 7,
        "postsCount": 4,
        "likesCount": 8,
        "commentsCount": 6,
        "savedCount": 8
    },
    {
        "userId": "c219fe76-dabe-4330-a23e-d9914d369134",
        "username": "Rodger_Erdman",
        "firstName": "Nolan",
        "lastName": "Beatty",
        "email": "Tia30@yahoo.com",
        "aboutMe": "symptom devotee",
        "avatar": "https://avatars.githubusercontent.com/u/45986099",
        "password": "M101pF_w5ihKBzX",
        "birthdate": "14/01/1975",
        "registeredAt": "04/07/2023",
        "userInfo": {
            "biograph": "public speaker",
            "company": "Aspernatur vel ea doloremque eveniet fugit. Quam reiciendis laboriosam aperiam iste temporibus officiis ea illum. Natus provident maiores harum doloribus.",
            "jobTitle": "National Metrics Manager",
            "school": "Amet aut expedita nisi inventore et quasi dolores. Libero maxime numquam nam. Nisi totam maxime vero nemo esse.",
            "languages": "Molestias voluptate sint accusantium asperiores. Tenetur impedit illo. Maiores accusamus repudiandae nam saepe veniam assumenda.",
            "socialMedia": {
                "facebook": "https://authentic-lift.net/",
                "twitter": "https://parallel-sculpture.org/",
                "instagram": "https://venerated-ashram.info",
                "linkedin": "https://periodic-med.info",
                "youtube": "https://quick-limestone.net",
                "website": "https://buttery-cube.name/"
            }
        },
        "followersCount": 32,
        "followingCount": 8,
        "postsCount": 7,
        "likesCount": 5,
        "commentsCount": 7,
        "savedCount": 4
    },
    {
        "userId": "2d4fd24d-0c32-4774-8988-a35c5bdf7744",
        "username": "Frieda_Witting93",
        "firstName": "Ophelia",
        "lastName": "Block",
        "email": "Carmella39@gmail.com",
        "aboutMe": "neighbourhood fan",
        "avatar": "https://avatars.githubusercontent.com/u/10499839",
        "password": "Or5oFmhDcX4Am7u",
        "birthdate": "20/02/1998",
        "registeredAt": "22/02/2023",
        "userInfo": {
            "biograph": "parent, scientist, philosopher",
            "company": "Adipisci pariatur explicabo non eius debitis consequatur eligendi id. Harum cum accusantium ab hic perspiciatis quibusdam amet praesentium. Quo architecto natus voluptates illum doloribus tempore rerum.",
            "jobTitle": "Product Marketing Assistant",
            "school": "Aliquam sequi distinctio a iure. Odio quod laudantium veritatis dolores beatae facere illo molestiae consequuntur. Veniam culpa corporis minus consequuntur.",
            "languages": "Eligendi harum similique unde. Exercitationem sint id ex facilis incidunt aliquid. Nulla molestiae accusamus dicta voluptas excepturi iste consequuntur nesciunt ad.",
            "socialMedia": {
                "facebook": "https://awesome-knife-edge.org/",
                "twitter": "https://neat-checkbook.info",
                "instagram": "https://skinny-tonality.org",
                "linkedin": "https://staid-inventor.com",
                "youtube": "https://idle-silk.org",
                "website": "https://gifted-skin.net"
            }
        },
        "followersCount": 13,
        "followingCount": 7,
        "postsCount": 7,
        "likesCount": 8,
        "commentsCount": 8,
        "savedCount": 4
    }
]


export default sampleUserData